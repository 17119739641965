import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import "./TorusViewer.css";

const TorusViewer = () => {
  const mountRef = useRef(null);
  const [showLoginPanel, setShowLoginPanel] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let scene, camera, renderer, shape, material, controls;
    const textureLoader = new THREE.TextureLoader();

    const init = () => {
      scene = new THREE.Scene();
      camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.toneMapping = THREE.ACESFilmicToneMapping;
      renderer.toneMappingExposure = 1;
      renderer.outputColorSpace = THREE.SRGBColorSpace; // Updated this line
      mountRef.current.appendChild(renderer.domElement);

      material = new THREE.MeshStandardMaterial({
        color: 0xff00c6,
        roughness: 0.1,
        metalness: 0.7,
        displacementScale: 0,
        transparent: true,
        opacity: 1,
        depthWrite: true,
        depthTest: true,
      });

      material.envMapIntensity = 1.2;

      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight.position.set(5, 5, 5);
      scene.add(directionalLight);

      const ambientLight = new THREE.AmbientLight(0xffffff, 0.1);
      scene.add(ambientLight);

      loadTextures();

      createShape("Torus", "Normal");

      camera.position.z = 3;

      controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.25;
      controls.enableZoom = true;
      controls.autoRotate = true;
      controls.autoRotateSpeed = 1.0;

      loadEnvironmentMap();

      animate();
    };

    const loadTextures = () => {
      const texturesToLoad = [
        {
          url: "https://threejs.org/examples/textures/brick_diffuse.jpg",
          type: "map",
        },
      ];

      texturesToLoad.forEach(({ url, type }) => {
        textureLoader.load(
          url,
          (texture) => {
            texture.colorSpace = THREE.SRGBColorSpace; // Added this line
            material[type] = texture;
            material.needsUpdate = true;
          },
          undefined,
          (err) => setError(`Error loading ${type} texture: ${err.message}`)
        );
      });
    };

    const loadEnvironmentMap = () => {
      const pmremGenerator = new THREE.PMREMGenerator(renderer);

      const rgbeLoader = new RGBELoader();
      rgbeLoader.load(
        "/venice_sunset_1k.hdr",
        (texture) => {
          const envMap = pmremGenerator.fromEquirectangular(texture).texture;
          scene.background = envMap;
          scene.environment = envMap;

          texture.dispose();
          pmremGenerator.dispose();

          renderer.render(scene, camera);
        },
        undefined,
        (err) => setError(`Error loading HDR: ${err.message}`)
      );
    };

    const createShape = (shapeType, resolution) => {
      if (shape) scene.remove(shape);

      let geometry;
      const resolutionMultiplier =
        resolution === "Normal" ? 1 : resolution === "4x" ? 2 : 4;

      switch (shapeType) {
        case "Sphere":
          geometry = new THREE.SphereGeometry(
            1,
            64 * resolutionMultiplier,
            64 * resolutionMultiplier
          );
          break;
        case "Cube":
          geometry = new THREE.BoxGeometry(
            1.5,
            1.5,
            1.5,
            64 * resolutionMultiplier,
            64 * resolutionMultiplier,
            64 * resolutionMultiplier
          );
          break;
        case "Torus":
        default:
          geometry = new THREE.TorusGeometry(
            0.7,
            0.3,
            64 * resolutionMultiplier,
            64 * resolutionMultiplier
          );
          break;
      }

      shape = new THREE.Mesh(geometry, material);
      shape.position.y = -0.4;
      shape.renderOrder = 999;
      scene.add(shape);
    };

    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };

    init();

    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (mountRef.current && renderer) {
        mountRef.current.removeChild(renderer.domElement);
      }
      if (controls) controls.dispose();
      if (renderer) renderer.dispose();
      if (material) material.dispose();
      if (shape) {
        if (shape.geometry) shape.geometry.dispose();
        if (shape.material) shape.material.dispose();
      }
      scene.traverse((object) => {
        if (object.geometry) object.geometry.dispose();
        if (object.material) {
          if (Array.isArray(object.material)) {
            object.material.forEach((material) => material.dispose());
          } else {
            object.material.dispose();
          }
        }
      });
    };
  }, []);

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <div ref={mountRef} className="absolute inset-0" />
    </div>
  );
};

export default TorusViewer;
