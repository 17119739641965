import React, { useState } from "react";
import TorusViewer from "../../components/TorusViewer";
import MultiUserAuth from "../../components/Auth/MultiUserAuth";

const Landing = () => {
  const [showLoginPanel, setShowLoginPanel] = useState(false);

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-900 to-indigo-800">
      <header className="absolute top-0 left-0 right-0 z-20 py-6 px-4 bg-gradient-to-b from-black/20 to-transparent">
        <nav className="flex justify-between items-center">
          <div className="text-white font-bold text-3xl">Welloki</div>
          <ul className="flex space-x-4">
            <li>
              <a href="#" className="text-white font-bold hover:text-gray-400">
                About
              </a>
            </li>
            <li>
              <a
                href="#"
                className="text-white font-bold hover:text-gray-400"
                onClick={(e) => {
                  e.preventDefault();
                  setShowLoginPanel(true);
                }}
              >
                Login
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <main className="relative">
        <TorusViewer />
      </main>

      <footer className="absolute bottom-0 left-0 right-0 text-center p-4 text-white">
        <p className="text-lg font-medium tracking-wide text-white shadow-text mx-auto w-full sm:w-3/4 md:w-1/2 py-2 px-5 rounded-lg">
          Health research from new angles
        </p>
        <p className="text-sm font-medium">
          &copy; 2024 Welloki. All rights reserved.
        </p>
      </footer>

      {/* Login Panel */}
      <div
        className={`
          fixed top-0 right-0 w-full sm:w-3/4 md:w-1/2 lg:w-1/3 h-full
          bg-gray-600
          bg-opacity-20 backdrop-blur-md shadow-lg 
          z-40 transition-transform duration-300 ease-in-out
          ${showLoginPanel ? "translate-x-0" : "translate-x-full"}
        `}
      >
        <div className="p-6">
          <MultiUserAuth />
          <button
            className="absolute top-4 right-6 text-white hover:text-gray-300 focus:outline-none"
            onClick={() => setShowLoginPanel(false)}
            aria-label="Close login panel"
          >
            <div className="w-6 h-6 relative transform rotate-45">
              <span className="block absolute left-1/2 top-0 w-0.5 h-6 bg-current"></span>
              <span className="block absolute top-1/2 left-0 h-0.5 w-6 bg-current"></span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Landing;
